// 产品详情
// mxy
<template>
  <div class="box flex-column align-center">
    <div class="iview flex-column" :style="{ minHeight: minHeight + 'px' }">
      <a-spin :spinning="spinning">
        <div class="flex heade">
          <img class="logo" :src="list.appIcon" alt="" />
          <div class="app_des">
            <div class="fist_line">
              <div class="name" :title="list.appName">
                {{ list.appName }}
              </div>
              <div class="right_top">
                <AppQRCode v-if="isOwn == true && isOverdue == false && miniPro"
                  :pictureUrl="miniPro" class="scan" />
              </div>
            </div>
            <div class="tip">{{ tip }}</div>
            <div class="price" v-if="block">
              {{
                list.appBuyWay == 0
                  ? "免费"
                  : "¥" + list.appPrice + "/" + list.appPriceUnitName
              }}
            </div>
            <!-- <div class="flex">
              <span v-if="list.oldVersionCode !== list.versionCode">{{
                list.oldVersionCode
              }}</span>
              <ArrowRightOutlined
                v-if="
                  list.versionCode &&
                  list.oldVersionCode !== null &&
                  list.oldVersionCode !== '' &&
                  list.oldVersionCode !== list.versionCode
                "
                style="margin-top: 5px; padding: 0 6px; font-size: 12px"
              />
              <span>{{ list.versionCode }}</span>
            </div> -->
          </div>

          <div class="btn">
            <span class="back" @click="back()">返回</span>
            <!-- 待确定 -->
            <!-- <div
              style="display: inline-block"
              v-if="
                isOwn == true &&
                isOverdue != true &&
                list.versionUpdating !== true &&
                list.needUpdate !== true
              "
            > -->
            <div
              style="display: inline-block"
              v-if="isOwn == true && isOverdue != true"
            >
              <a-button
                type="primary"
                :class="isOwn == true ? '' : 'disabledBtn'"
                disabled
                >已添加</a-button
              >
            </div>
            <div style="display: inline-block">
              <!-- 待确定 -->
              <!-- <a-button
                v-if="
                  list.needUpdate == true &&
                  list.versionUpdating == false &&
                  isOwn == true &&
                  isOverdue != true &&
                  list.oldVersionCode !== list.versionCode
                "
                @click="update(list)"
                type="primary"
                >更新</a-button
              >

              <a-button
                v-if="list.versionUpdating === true"
                :class="list.versionUpdating == true ? '' : 'disabledBtn'"
                disabled
                type="primary"
                >更新中</a-button
              > -->
            </div>
            <div style="display: inline-block" v-if="isOwn == false">
              <a-button
                :disabled="!disabledTenant || disabledBtn"
                :title="!disabledTenant || disabledBtn ? '暂无权限' : ''"
                :class="disabledBtn === true ? '' : 'disabledBtn'"
                type="primary"
                :loading="loading"
                @click="purchase(list)"
                v-if="list.appBuyWay === 1 && list.isTrial == false"
                >购买</a-button
              >
              <a-button
                :disabled="!disabledTenant || disabledBtn"
                :title="!disabledTenant || disabledBtn ? '暂无权限' : ''"
                :class="disabledBtn === true ? '' : 'disabledBtn'"
                type="primary"
                :loading="loading"
                @click="purchase(list)"
                v-if="isOverdue == true"
                >购买</a-button
              >
              <a-button
                :disabled="!disabledTenant || disabledBtn"
                :title="!disabledTenant || disabledBtn ? '暂无权限' : ''"
                :class="disabledBtn === true ? '' : 'disabledBtn'"
                type="primary"
                :loading="loading"
                @click="freeTrial()"
                v-if="list.appBuyWay === 1 && list.isTrial == true"
                >免费试用</a-button
              >
              <a-button
                :disabled="!disabledTenant || disabledBtn"
                :title="!disabledTenant || disabledBtn ? '暂无权限' : ''"
                :class="disabledBtn === true ? '' : 'disabledBtn'"
                type="primary"
                :loading="loading"
                @click="freeTrial(true)"
                v-if="list.appBuyWay === 0"
                >添加</a-button
              >
            </div>
            <div style="display: inline-block" v-if="isOverdue == true">
              <a-button
                :disabled="!disabledTenant || disabledBtn"
                :class="disabledBtn === true ? '' : 'disabledBtn'"
                type="primary"
                :title="!disabledTenant || disabledBtn ? '暂无权限' : ''"
                :loading="loading"
                @click="purchase(list)"
                v-if="list.appBuyWay === 1 && list.isTrial == true"
                >购买</a-button
              >
            </div>
          </div>
        </div>

        <div class="container">
          <h1 class="title">{{ list.publishTitle }}</h1>
          <h4 v-html="list.detailedContent"></h4>
        </div>
        <div class="footer">
          <div class="title" v-if="screenshot">功能截图</div>
          <a-empty v-if="screenshot.length === 0" />
          <a-carousel autoplay>
            <div v-for="item in screenshot" class="imageBox" :key="item">
              <!-- <img :src="item.pictureURL" class="picture" /> -->
              <div
                class="picture"
                :style="{
                  'background-image': 'url(' + item.pictureURL + ')',
                }"
              ></div>
            </div>
          </a-carousel>
        </div>
      </a-spin>
      <a-modal
        title="到期提醒"
        :visible="overdueVisible"
        :confirm-loading="confirmLoading"
        cancelText="取消"
        :maskClosable="false"
        okText="确认"
        @ok="overdueCancel"
        @cancel="overdueCancel"
      >
        <div>
          <div class="overdue-title">{{ titleList.appName }}</div>
          <div>
            请点击
            <span class="buy font-size" @click="buy()">联系购买</span>
            或拨打电话 <span class="font-size">15862885968</span>
          </div>
          <!-- <span>{{ titleList.expireTime }}</span>
        <span>—</span>
        <span>{{ titleList.expireTime }}</span>
        <div>试用{{titleList.trialValidity}}天,</div> -->
        </div>
      </a-modal>
      <a-modal
        title="购买信息"
        :visible="visible"
        cancelText="取消"
        :maskClosable="false"
        okText="确认"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <a-spin :spinning="spinning">
          <a-form
            :model="register"
            ref="formRef"
            :rules="rules"
            autocomplete="off"
            :labelCol="{ span: 6 }"
            :wrapperCol="{ span: 18 }"
          >
            <a-form-item required name="name" label="姓名">
              <a-input
                style="width: 80%"
                size="large"
                :maxLength="30"
                type="text"
                v-model:value="register.name"
                placeholder="请输入"
              >
              </a-input>
            </a-form-item>
            <a-form-item required name="phoneNumber" label="手机号">
              <a-input
                style="width: 80%"
                size="large"
                :maxLength="11"
                type="text"
                v-model:value="register.phoneNumber"
                placeholder="请输入"
              >
              </a-input>
            </a-form-item>
            <a-form-item required name="companyName" label="企业名称">
              <a-input
                style="width: 80%"
                size="large"
                :maxLength="30"
                type="text"
                v-model:value="register.companyName"
                placeholder="请输入"
              >
              </a-input>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import AppQRCode from '@/components/AppQRCode'
// import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons-vue'
import { minheight } from '../components/sameVariable'
import { getProfileDetail } from '@/api/IdentityAPI'
import { tenantIsComplete, VersionDue } from '@/assets/common.js'
export default defineComponent({
  components: {
    // LeftCircleOutlined,
    // RightCircleOutlined,
    // ArrowRightOutlined,
    // 'usage-record': UsageRecord,
    AppQRCode
  },
  data () {
    return {
      block: false,
      spinning: false,
      loading: false,
      disabledTenant: false,
      disabledBtn: false,
      list: {},
      value2: 0,
      query: {},
      screenshot: [],
      isOwn: null,
      isOverdue: null,
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '请输入正确的11位手机号码',
            trigger: 'blur',
          },
        ],
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      visible: false,
      overdueVisible: false,
      confirmLoading: false,
      register: {
        name: '',
      },
      titleList: {},
      titleListTime: '',
      tip: ''
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.query = this.$route.query
    this.tip = this.query.tip
    this.miniPro = this.query.miniPro
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    // if (tenantList.isFree === true) {
    //   this.disabledBtn = true
    // }
  },
  mounted () {
    this.getApplication()
    this.getIsOwn()
    this.getIsOverdue()
  },
  // 方法
  methods: {
    // 更新
    update (item) {
      if (VersionDue()) {
        this.$message.warning('当前企业版本已过期，请联系客服人员')
        return
      }
      // console.log(item)
      this.spinning = true
      const obj = {
        id: item.id,
      }
      api
        .post('/api/app/sys-application-on/sys-application-on-version-upgrade', obj)
        .then(({ data }) => {
          if (data === true) {
            this.$message.success('更新成功')
          } else {
            this.$message.error('更新失败')
          }
          this.spinning = false
          this.getApplication()
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          console.log(err)
        })
    },
    // 免费试用
    freeTrial (item) {
      if (VersionDue()) {
        this.$message.warning('当前企业版本已过期，请联系客服人员')
        return
      }
      this.loading = true
      api
        .post('/api/app/sys-application-on/application-on-trial', {
          id: this.query.id,
        })
        .then(({ data }) => {
          this.loading = false
          if (item) {
            this.$message.success('添加成功')
          } else {
            this.$message.success('试用成功')
          }
          this.getIsOwn()
          this.getIsOverdue()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          // this.$message.error('试用失败' + err)
        })
    },
    getApplication () {
      this.spinning = true
      this.loading = true
      this.disabledTenant = tenantIsComplete()
      api
        .get('/api/app/sys-application/sys-application', {
          params: this.query,
        })
        .then(({ data }) => {
          console.log('data', data);
          this.list = data
          this.block = true
          this.screenshot = data.appPictureList
          this.spinning = false
          this.loading = false
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          this.loading = false
          console.log(err)
        })
    },
    getIsOwn () {
      this.loading = true
      this.spinning = true
      api
        .get('/api/app/sys-application-on/application-is-own', {
          params: this.query,
        })
        .then(({ data }) => {
          this.isOwn = data
          this.spinning = false
          this.loading = false
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          this.loading = false
          console.log(err)
        })
    },
    getIsOverdue () {
      this.loading = true
      this.spinning = true
      api
        .get('/api/app/sys-application-on/application-is-overdue', {
          params: this.query,
        })
        .then(({ data }) => {
          this.isOverdue = data
          this.spinning = false
          this.loading = false
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          this.loading = false
          console.log(err)
        })
    },
    back () {
      this.$router.push({ path: '/ProductCentre/AllProduct' })
    },
    // 购买
    purchase (item) {
      if (VersionDue()) {
        this.$message.warning('当前企业版本已过期，请联系客服人员')
        return
      }
      this.overdueVisible = true
      this.spinning = false
      this.titleList = item
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        this.register.name = data.name
        this.register.phoneNumber = data.phoneNumber
      })
      const tenantList = JSON.parse(localStorage.getItem('tenantList'))
      this.register.companyName = tenantList.enterpriseName
    },
    buy () {
      this.visible = true
    },
    // 到期提醒框取消
    overdueCancel () {
      this.overdueVisible = false
    },
    // 确定
    handleOk () {
      this.$refs.formRef.validate().then(() => {
        this.spinning = true
        const obj = {
          name: this.register.name,
          phone: this.register.phoneNumber,
          companyName: this.register.companyName,
          versionName: this.list.appName,
        }
        // console.log(obj)
        api
          .post('/api/app/message/purchase-version', obj)
          .then(({ data }) => {
            this.spinning = false
            this.$message.success('您已成功提交购买信息！')
            this.handleCancel()
            this.overdueCancel()
            this.overdueVisible = false
          })
          .catch(err => {
            this.spinning = false
            // this.$message.error('创建失败' + err)
            console.log(err)
          })
      })
    },
    // 取消
    handleCancel () {
      this.visible = false
      this.$refs.formRef.resetFields()
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.box {
  width: 100%;
  height: 100%;
}
.app_des {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .tip {
    font-size: 12px;
    color: #999999;
  }
}

.iview {
  width: 82%;
  padding: 10px 100px;
  .heade {
    height: 100px;
    // line-height: 100px;
    margin: 40px 0;
    position: relative;
  }
  .logo {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .fist_line {
    display: flex;
    flex-direction: row;
    align-items: center;
    .right_top {
      margin-left: 10px;
    }
  }
  .name {
    opacity: 1;
    // width: 260px;
    font-size: 20px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .price {
    color: #ff6662;
    margin: 4px 0;
  }
  .edition {
    color: #bbb;
  }
  .btn {
    position: absolute;
    top: 36px;
    right: 0;
    .back {
      margin-right: 20px;
      color: #aaa;
      cursor: pointer;
      position: relative;
      top: 7px;
    }
  }
  .footer {
    margin: 40px 0;
  }
  .title {
    margin-bottom: 40px;
    height: 40px;
    opacity: 1;
    font-size: 24px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    text-align: left;
    color: #333333;
  }
}
.anticon-right-circle {
  display: none;
}

.imageBox {
  width: 99%;
  height: 566px;
  overflow: hidden;
}
.picture {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 50%;
  margin: 2px;
}

.ant-carousel ::v-deep(.slick-dots)li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(51, 50, 50);
  margin-right: 4px;
  z-index: 9999;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: #1f1f1f;
}
.ant-menu-horizontal .ant-menu-item {
  margin: 0 40px;
}
.overdue-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.buy {
  cursor: pointer;
}
::v-deep(.disabledBtn.ant-btn) {
  background: #134ccf;
  color: #fff;
}
::v-deep(.disabledBtn.ant-btn:hover, .ant-btn:focus) {
  opacity: 0.7;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  width: 100%;
  height: 566px;
  overflow: hidden;
}
.ant-carousel {
  margin: 6px;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
